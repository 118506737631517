<template>
  <div>
    <v-row class="mb-0">
      <!-- <v-col cols="12" md="6" sm="12">
        <v-container class="fill-height px-12">
          <div class="text-left">
            <h3 class="mb-4 display-1">
              Unisciti a colleghi e professionisti del Non Profit in un percorso
              di dialogo e condivisione continua sul presente e futuro del
              sociale
            </h3>
            <p class="font-weight-light title mb-8">
              Accedi a contenuti esclusivi e confrontati direttamente con chi,
              come te, vuole fare del bene sempre meglio
            </p>
            <v-btn
              color="primary"
              class="uppercase mb-4"
              @click="$vuetify.goTo('#about-ts-together')"
              large
              >Scopri di più</v-btn
            >
          </div>
        </v-container>
      </v-col> -->
      <v-col cols="12" md="12" sm="12" class="pb-0">
        <v-img
          :min-height="minHeight"
          :max-height="maxHeight"
          :src="require('@/assets/hero.jpg')"
          class="white--text"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,1)"
        >
          <!-- <v-container class="fill-height px-4 py-12">
            <v-responsive class="d-flex align-center" height="100%" max-width="700" width="100%">
              <div class="mb-8">
                <h1
                  class="subheading white--text display-1 mb-3"
                >Unisciti alle voci piú influenti del non-profit in Italia in un percorso di formazione e discussione continua sul futuro del sociale.</h1>

                <p
                  class
                >Accedi a contenuti esclusivi e confrontati direttamente con chi, come te, vuole fare del bene sempre meglio.</p>
              </div>

              <div
                :class="$vuetify.breakpoint.smAndDown ? 'flex-column align-start' : 'align-center'"
                class="d-flex flex-wrap"
              >
                <v-btn color="primary" large>Discover More</v-btn>
              </div>

              <p class="caption pt-5">
                7 giorni di prova gratuita. €14/mese (billing annuale).
                <br />Se non é per te, disdici quando vuoi.
              </p>
            </v-responsive>
          </v-container>-->
        </v-img>
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>

<script>
export default {
  name: "HeroSection",

  computed: {
    minHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "60vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
    maxHeight() {
      const height = this.$vuetify.breakpoint.mdAndUp ? "60vh" : "50vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    },
  },
};
</script>
